const main = [
	{
		"id": 'main',
		"label": "Main"
	},
	{
		"id": 1,
		"icon": "icon-home",
		"label": "Overview",
		"to": "/",
		content: [
			// {
			// 	"id": 2,
			// 	"label": "My Dashboard",
			// 	"to": "/"
			// },
			{
				"id": 3,
				"label": "Platform Analytics",
				"to": "/"
			},
			// {
			// 	"id": 4,
			// 	"label": "Event Monitoring",
			// 	"to": "/dashboard5"
			// },
			// {
			// 	"id": 5,
			// 	"label": "Finance Performance",
			// 	"to": "/dashboard6"
			// },
			// {
			// 	"id": 6,
			// 	"label": "Sales Monitoring",
			// 	"to": "/dashboard7"
			// },
			// {
			// 	"id": 7,
			// 	"label": "Campaign Monitoring",
			// 	"to": "/dashboard9"
			// },
			// {
			// 	"id": 9,
			// 	"label": "University Analytics",
			// 	"to": "/dashboard10"
			// },
			{
				"id": 10,
				"label": "Marketplace Analytics",
				"to": "/analytics"
			}
		]
	},
	{
		"id": 11,
		"icon": "icon-users",
		"label": "Users",
		"to": "/users",
	},
	{
		"id": 16,
		"icon": "icon-docs",
		"label": "Projects",
		"to": "/projects",
	},
	{
		"id": 21,
		"icon": "icon-microphone",
		"label": "Audio Messages",
		"to": "/audios",
	},
	{
		"id": 'store',
		"label": "Store"
	},
	{
		"id": 12,
		"icon": "icon-tag",
		"label": "Marketplace",
		"to": "/",
		content: [
			{
				"id": 15,
				"label": "Transactions",
				"to": "/transactions"
			},
			{
				"id": 13,
				"label": "Products",
				"to": "/products"
			},
			{
				"id": 14,
				"label": "Categories",
				"to": "/categories"
			}
		]
	},
	// {
	// 	"id": 15,
	// 	"icon": "icon-envelope",
	// 	"label": "Email",
	// 	"to": "/app-inbox"
	// },
	// {
	// 	"id": 16,
	// 	"icon": "icon-bubbles",
	// 	"label": "Messenger",
	// 	"to": "/app-chat"
	// },
	// {
	// 	"id": 17,
	// 	"icon": "icon-bubbles",
	// 	"label": "Project	",
	// 	"to": "/",
	// 	content: [
	// 		{
	// 			"id": 18,
	// 			"label": "Taskboard",
	// 			"to": "/task-board"
	// 		},
	// 		{
	// 			"id": 19,
	// 			"label": "Project List",
	// 			"to": "/app-project-list"
	// 		},
	// 		{
	// 			"id": 20,
	// 			"label": "Ticket List",
	// 			"to": "/app-ticket"
	// 		},
	// 		{
	// 			"id": 201,
	// 			"label": "Ticket Details",
	// 			"to": "/app-ticket-details"
	// 		},
	// 		{
	// 			"id": 202,
	// 			"label": "Clients",
	// 			"to": "/app-clients"
	// 		},
	// 		{
	// 			"id": 203,
	// 			"label": "Todo List",
	// 			"to": "/app-todo"
	// 		},
	// 	]
	// },
	// {
	// 	"id": 21,
	// 	"icon": "icon-calendar",
	// 	"label": "Calendar",
	// 	"to": "/app-calendar"
	// },
	// {
	// 	"id": 'ui',
	// 	"label": "UI Elements"
	// },
	// {
	// 	"id": 22,
	// 	"icon": "icon-tag",
	// 	"label": "Icons",
	// 	"to": "/",
	// 	content: [
	// 		{
	// 			"id": 23,
	// 			"label": "FontAwesome",
	// 			"to": "/ui-icons"
	// 		},
	// 	]
	// },
	// {
	// 	"id": 26,
	// 	"icon": "icon-diamond",
	// 	"label": "Components",
	// 	"to": "/",
	// 	content: [
	// 		{
	// 			"id": 27,
	// 			"label": "Bootstrap UI",
	// 			"to": "/ui-bootstrap"
	// 		},
	// 		{
	// 			"id": 28,
	// 			"label": "Typography",
	// 			"to": "/ui-typography"
	// 		},
	// 		{
	// 			"id": 72,
	// 			"label": "Colors",
	// 			"to": "/ui-colors"
	// 		},
	// 		{
	// 			"id": 73,
	// 			"label": "Buttons",
	// 			"to": "/ui-buttons"
	// 		},
	// 		{
	// 			"id": 29,
	// 			"label": "Tabs",
	// 			"to": "/tabs"
	// 		},
	// 		{
	// 			"id": 30,
	// 			"label": "Progress Bars",
	// 			"to": "/progressbars"
	// 		},
	// 		{
	// 			"id": 31,
	// 			"label": "Modals",
	// 			"to": "/modals"
	// 		},
	// 		{
	// 			"id": 32,
	// 			"label": "Notifications",
	// 			"to": "/notifications"
	// 		},
	// 		{
	// 			"id": 74,
	// 			"label": "Dialogs",
	// 			"to": "/ui-dialogs"
	// 		},
	// 		{
	// 			"id": 75,
	// 			"label": "List Group",
	// 			"to": "/ui-list-groups"
	// 		},
	// 		{
	// 			"id": 76,
	// 			"label": "Media Object",
	// 			"to": "/ui-media-object"
	// 		},
	// 		{
	// 			"id": 77,
	// 			"label": "Nestable",
	// 			"to": "/ui-nestable"
	// 		},

	// 		{
	// 			"id": 33,
	// 			"label": "Range Sliders",
	// 			"to": "/ui-range-sliders"
	// 		}
	// 	]
	// },
	// {
	// 	"id": 34,
	// 	"icon": "icon-pencil",
	// 	"label": "Forms",
	// 	"to": "/",
	// 	content: [
	// 		{
	// 			"id": 35,
	// 			"label": "Basic Elements",
	// 			"to": "/form/Form-basic"
	// 		},
	// 		{
	// 			"id": 36,
	// 			"label": "Advanced Elements",
	// 			"to": "/form/form-advanced"
	// 		},
	// 		{
	// 			"id": 37,
	// 			"label": "Form Validation",
	// 			"to": "/form/form-validation"
	// 		},
	// 		{
	// 			"id": 38,
	// 			"label": "Form Wizard",
	// 			"to": "/form/form-wizard"
	// 		},
	// 		{
	// 			"id": 39,
	// 			"label": "Summernote",
	// 			"to": "/form/form-summernote"
	// 		},
	// 		{
	// 			"id": 40,
	// 			"label": "Drag & Drop Upload",
	// 			"to": "/form/form-dragdropupload"
	// 		},
	// 		{
	// 			"id": 41,
	// 			"label": "CKEditor",
	// 			"to": "/form/form-editors"
	// 		},
	// 		{
	// 			"id": 43,
	// 			"label": "Image Cropping",
	// 			"to": "/form/form-cropping"
	// 		},
	// 	]
	// },
	// {
	// 	"id": 44,
	// 	"icon": "icon-layers",
	// 	"label": "Tables",
	// 	"to": "/",
	// 	content: [
	// 		{
	// 			"id": 45,
	// 			"label": "Normal Tables",
	// 			"to": "/table/table-normal"
	// 		},
	// 		{
	// 			"id": 46,
	// 			"label": "Jquery Datatables",
	// 			"to": "/table/table-jquery-datatable"
	// 		},
	// 		{
	// 			"id": 47,
	// 			"label": "Table Filter",
	// 			"to": "/table/table-filter"
	// 		},
	// 		{
	// 			"id": 48,
	// 			"label": "Editable Tables",
	// 			"to": "/table/table-editable"
	// 		},
	// 		{
	// 			"id": 49,
	// 			"label": "Table dragger",
	// 			"to": "/table/table-dragger"
	// 		},
	// 		{
	// 			"id": 50,
	// 			"label": "Tables Color",
	// 			"to": "/table/table-color"
	// 		},
	// 	]
	// },
	// {
	// 	"id": 51,
	// 	"icon": "icon-pie-chart",
	// 	"label": "Charts",
	// 	"to": "/",
	// 	content: [
	// 		{
	// 			"id": 52,
	// 			"label": "Apex Charts",
	// 			"to": "/chart/chart-apex"
	// 		},
	// 	]
	// },
	// {
	// 	"id": 53,
	// 	"icon": "icon-map",
	// 	"label": "jVector Map",
	// 	"to": "/app-jVector-map"
	// },
	// {
	// 	"id": 'extra',
	// 	"label": "Extra"
	// },
	// {
	// 	"id": 54,
	// 	"icon": "icon-puzzle",
	// 	"label": "Widgets",
	// 	"to": "/widgets"
	// },
	// {
	// 	"id": 55,
	// 	"icon": "icon-lock",
	// 	"label": "Authentication",
	// 	"to": "/",
	// 	content: [
	// 		{
	// 			"id": 56,
	// 			"label": "Login",
	// 			"to": "/login"
	// 		},
	// 		{
	// 			"id": 57,
	// 			"label": "Register",
	// 			"to": "/signup"
	// 		},
	// 		{
	// 			"id": 58,
	// 			"label": "Forgot Password",
	// 			"to": "/forgotpassword"
	// 		},
	// 		{
	// 			"id": 59,
	// 			"label": "Page 404",
	// 			"to": "/notfound"
	// 		},
	// 		{
	// 			"id": 60,
	// 			"label": "Maintenance",
	// 			"to": "/maintenance"
	// 		},
	// 	]
	// },
	// {
	// 	"id": 61,
	// 	"icon": "icon-docs",
	// 	"label": "Pages",
	// 	"to": "/",
	// 	content: [
	// 		{
	// 			"id": 62,
	// 			"label": "Page Blank",
	// 			"to": "/page-blank"
	// 		},
	// 		{
	// 			"id": 63,
	// 			"label": "Page Profile",
	// 			"to": "/page-profile"
	// 		},
	// 		{
	// 			"id": 64,
	// 			"label": "Page User List",
	// 			"to": "/page-user-list"
	// 		},
	// 		{
	// 			"id": 65,
	// 			"label": "Page Testimonials",
	// 			"to": "/page-testimonials"
	// 		},
	// 		{
	// 			"id": 66,
	// 			"label": "Page Invoices",
	// 			"to": "/page-invoices"
	// 		},
	// 		{
	// 			"id": 67,
	// 			"label": "Page Timeline",
	// 			"to": "/page-timeline"
	// 		},
	// 		{
	// 			"id": 68,
	// 			"label": "Page Search Results",
	// 			"to": "/page-search-results"
	// 		},
	// 		{
	// 			"id": 69,
	// 			"label": "Page Gallery",
	// 			"to": "/page-gallery"
	// 		},
	// 		{
	// 			"id": 70,
	// 			"label": "Page Pricing",
	// 			"to": "/page-pricing"
	// 		},
	// 		{
	// 			"id": 71,
	// 			"label": "Page Coming Soon",
	// 			"to": "/page-coming-soon"
	// 		},
	// 	]
	// },
];

const kitso = [
	{
		"id": 'main',
		"label": "Main"
	},
	{
		"id": 1,
		"icon": "icon-home",
		"label": "Overview",
		"to": "/",
	},
	{
		"id": 2,
		"icon": "icon-users",
		"label": "Users",
		"to": "/users",
	},
	{
		"id": 3,
		"icon": "icon-note",
		"label": "Posts",
		"to": "/posts",
		"content": [
			{
				"id": 4,
				"label": "Images",
				"to": "/posts"
			},
			{
				"id": 5,
				"label": "Videos",
				"to": "/videos"
			},
			{
				"id": 6,
				"label": "Audio",
				"to": "/audios"
			},
			{
				"id": 7,
				"label": "Manage Categories",
				"to": "/categories"
			},
		]
	},
]

export default { main, kitso }	