import React, { useEffect, useState } from 'react';
import ReactApexChart from "react-apexcharts";

const SalesChart = ({ finances, sales }) => {

    const [series, setseries] = useState()
    const [months, setmonths] = useState([])

    useEffect(() => {
        setseries([{ 
            name: `Revenue`,
            data: Object.values(finances ? finances : {}) 
        },
        { 
            name: `Sales`,
            data: Object.values(sales ? sales : {}) 
        }])
        setmonths(Object.keys(finances ? finances : {}))
    }, [finances, sales])
    
    if(!series){
        return <></>
    }

    return (
        <div>
            <ReactApexChart
                options={{
                    chart: {
                        type: 'area',
                        stacked: false,
                        toolbar: {
                            show: false
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    colors: ['#ffd861', '#E15858'],
                    markers: {
                        size: 0,
                    },
                    stroke: {
                        width: 1
                    },
                    fill: {
                        type: 'gradient',
                        gradient: {
                            shadeIntensity: 0.6,
                            inverseColors: false,
                            opacityFrom: 0.2,
                            opacityTo: 0,
                            stops: [0, 90, 100]
                        },
                    },
                    yaxis: {
                        labels: {
                            formatter: function (val) {
                                return `${val.toFixed(0)}`;
                            },
                        },
                        min: 0,
                        // max: 70,
                        // tickAmount: 4
                    },
                    xaxis: {
                        axisTicks: { show: true },
                        categories: months
                    },
                    legend: {
                        show: false
                    },
                    tooltip: {
                        show: true
                    }
                }}
                series={series}
                type="area"
                height="320px"
            />
        </div>
    );
}

export default SalesChart;
