import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { ConfirmDialog } from 'primereact/confirmdialog'
import { confirmDialog } from 'primereact/confirmdialog'
import { TabView, TabPanel } from 'primereact/tabview';
import { Dialog } from 'primereact/dialog';
import readXlsxFile from 'read-excel-file'
import axios from 'axios';

const PageUserList = (props) => {

    const toast = useRef(null);
    const [name, setname] = useState()
    const [bulkupload, setbulkupload] = useState(false)
    const [surname, setsurname] = useState()
    const [email, setemail] = useState()
    const [password, setpassword] = useState()
    const [loading, setloading] = useState()
    const [users, setusers] = useState([])

    if(!localStorage.getItem(`_auth`)){
		props.history.push('/login')
	}

    const getusers = () => {
        axios.get(`/users`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('_auth')}`,
                'x-refresh': `${localStorage.getItem('_auth0')}`,
                'x-platform': localStorage.getItem(`_platform`)
            }
        }).then(response=>{
            setusers(response.data.users)
        }).catch(err=>{
            try{
                toast.current.show({ severity: 'warn', summary: 'Error!', detail: err.response.data.message, life: 3000 })
            }catch(e){
                toast.current.show({ severity: 'warn', summary: 'Error!', detail: err.message, life: 3000 })
            }
        })
    }
    
    const bulkadd = async e => {
        let users = []

        readXlsxFile(e.target.files[0]).then((data) => {
            data.map(user=>
                users.push({
                    name: user[0],
                    surname: user[1],
                    email: user[2],
                    password: user[3],
                    type: user[4] || `basic`
                })
            )
            submitbulk(users)
        })
    }

    const submitbulk = async data => {
        toast.current.show({ severity: 'info', summary: 'Processing!', detail: `Your bulk upload request is being processed, please wait...` })

        try{
            let response = await axios.post(`/users/bulk`, { users: data }, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('_auth')}`,
                    'x-refresh': `${localStorage.getItem('_auth0')}`,
                    'x-platform': localStorage.getItem(`_platform`)
                }
            })
            console.log(response.data)
            toast.current.show({ severity: 'success', summary: 'Completed Successfully!', detail: `Your bulk add request was processed successfully, users can now login to the system/platform.`, life: 3000 })
            setloading(false)
            setbulkupload(false)
            getusers()
        }catch(err){
            setloading(false)
            try{
                toast.current.show({ severity: 'error', summary: 'Error!', detail: err.response.data.message, life: 3000 })
            }catch(e){
                toast.current.show({ severity: 'warn', summary: 'Error!', detail: err.message, life: 3000 })
            }
        }
    }

    const del = async(user)=>{
        try{
            await axios.delete(`/users/${user.uid}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('_auth')}`,
                    'x-refresh': `${localStorage.getItem('_auth0')}`,
                    'x-platform': localStorage.getItem(`_platform`)
                }
            })
            toast.current.show({ severity: 'success', summary: 'Deleted!', detail: `You have deleted the user account: ${user.name}`, life: 3000 })
            getusers()
        }catch(err){
            try{
                toast.current.show({ severity: 'error', summary: 'Error!', detail: err.response.data.message, life: 3000 })
            }catch(e){
                toast.current.show({ severity: 'warn', summary: 'Error!', detail: err.message, life: 3000 })
            }
        }

    }

    const ban = async(user)=>{
        try{
            await axios.put(`/users/ban/${user.uid}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('_auth')}`,
                    'x-refresh': `${localStorage.getItem('_auth0')}`,
                    'x-platform': localStorage.getItem(`_platform`)
                }
            })
            toast.current.show({ severity: 'success', summary: 'Banned!', detail: `You have banned the user account: ${user.name}`, life: 3000 })
            getusers()
        }catch(err){
            try{
                toast.current.show({ severity: 'error', summary: 'Error!', detail: err.response.data.message, life: 3000 })
            }catch(e){
                toast.current.show({ severity: 'warn', summary: 'Error!', detail: err.message, life: 3000 })
            }
        }

    }

    const activate = async(user)=>{
        try{
            await axios.put(`/users/activate/${user.uid}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('_auth')}`,
                    'x-refresh': `${localStorage.getItem('_auth0')}`,
                    'x-platform': localStorage.getItem(`_platform`)
                }
            })
            toast.current.show({ severity: 'success', summary: 'Activate!', detail: `You have activate the user account: ${user.name}`, life: 3000 })
            getusers()
        }catch(err){
            try{
                toast.current.show({ severity: 'error', summary: 'Error!', detail: err.response.data.message, life: 3000 })
            }catch(e){
                toast.current.show({ severity: 'warn', summary: 'Error!', detail: err.message, life: 3000 })
            }
        }

    }
    
    const cancel = ()=>{}

    const deleteUser = (user)=>{
        confirmDialog({
            message: 'Do you want to delete this user account?',
            header: `Delete ${user.name}?`,
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept: ()=>del(user),
            reject: cancel
        });
    }

    const banUser = (user)=>{
        confirmDialog({
            message: 'Do you want to ban this user account?',
            header: `Ban ${user.name}?`,
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-warning',
            accept: ()=>ban(user),
            reject: cancel
        });
    }

    const activateUser = (user)=>{
        confirmDialog({
            message: 'Do you want to activate this user account?',
            header: `Activate ${user.name}?`,
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-success',
            accept: ()=>activate(user),
            reject: cancel
        });
    }

    const addAdmin = async e => {
        e.preventDefault();
        
        setloading(true)

        try{
            await axios.post(`/users`, {
                name,
                surname,
                email,
                password,
                type: `admin`
            }, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('_auth')}`,
                    'x-refresh': `${localStorage.getItem('_auth0')}`,
                    'x-platform': localStorage.getItem(`_platform`)
                }
            })
            toast.current.show({ severity: 'success', summary: 'Added!', detail: `${name} has been added to your administrators.`, life: 3000 })
            setloading(false)
            setname('')
            setsurname('')
            setemail('')
            setpassword('')
        }catch(err){
            setloading(false)
            try{
                toast.current.show({ severity: 'error', summary: 'Error!', detail: err.response.data.message, life: 3000 })
            }catch(e){
                toast.current.show({ severity: 'warn', summary: 'Error!', detail: err.message, life: 3000 })
            }
        }
    }

    useEffect(() => {
        document.title = `Users | DoGood`
        getusers()
    }, [])
    

    return (
        <>
            <Toast ref={toast} />
            <ConfirmDialog />
            <Dialog header="Bulk Add Users" visible={bulkupload} onHide={()=>setbulkupload(false)}>
                <div className="row clearfix">
                    <div className="col-md-12 col-sm-12">
                        <input type='file' onChange={bulkadd} disabled={loading} accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' title="Upload Excel Sheet with Users Details" placeholder='Upload Excel Sheet with Users Details' />
                    </div>
                </div>
            </Dialog>
            <div className="container-fluid">
                <div className="block-header">
                    <div className="row clearfix">
                        <div className="col-md-6 col-sm-12">
                            <h2>User List</h2>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/">Overview</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Users</li>
                                </ol>
                            </nav>
                        </div>
                        <div className="col-md-6 col-sm-12 text-right hidden-xs">
                            <a className="btn btn-sm btn-primary btn-round" title="" onClick={e=>{ e.preventDefault(); setbulkupload(true)}} data-toggle="tab" href="/">Bulk Add Users</a>
                        </div>
                    </div>
                </div>
                <div className="row clearfix">
                    <div className="col-lg-12">
                        <div>
                            <div className='card'>
                                <TabView>
                                    <TabPanel header="Users">
                                        <div className="table-responsive">
                                            <table className="table table-hover table-custom spacing8">
                                                <thead>
                                                    <tr>
                                                        <th className="w60">Name</th>
                                                        <th></th>
                                                        <th>Account Type</th>
                                                        <th>Account Status</th>
                                                        <th>Created Date</th>
                                                        <th>Last Login</th>
                                                        <th className="w100">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    { users.map(user => <tr key={user.uid}>
                                                        <td>
                                                            <img src={user.photoURL} data-toggle="tooltip" data-placement="top" title="" alt="Avatar" className="w35 h35 rounded" data-original-title="Avatar Name" />
                                                        </td>
                                                        <td>
                                                            <h6 className="mb-0">{user.name} {user.surname}</h6>
                                                            <span>{user.email}</span>
                                                        </td>
                                                        <td><span className={`badge badge-${user.type === 'admin' ? 'primary' : 'success'}`}>{user.type}</span></td>
                                                        <td><span className={`text-${user.status === 'suspended' ? 'danger' : 'primary'}`}>{user.status.toUpperCase()}</span></td>
                                                        <td>{user.created}</td>
                                                        <td>{user.lastLogin}</td>
                                                        <td>
                                                            { user.type !== 'admin' ?
                                                            <>
                                                            <button type="button" className="btn btn-sm btn-primary" title="Edit"><i className="fa fa-edit"></i></button>
                                                            { user.status === 'suspended' ?
                                                            
                                                            <button type="button" onClick={()=>activateUser(user)} className="btn btn-sm btn-info m-l-5 m-r-5" title="Activate User"><i className="fa fa-check-square-o"></i></button>
                                                            :
                                                            <button type="button" onClick={()=>banUser(user)} className="btn btn-sm btn-warning m-l-5 m-r-5" title="Ban User"><i className="fa fa-ban"></i></button>
                                                            }
                                                            <button type="button" onClick={()=>deleteUser(user)} className="btn btn-sm btn-danger" title="Delete" data-type="confirm"><i className="fa fa-trash-o"></i></button>
                                                            </> : <></> }
                                                        </td>
                                                    </tr>) }
                                                </tbody>
                                            </table>
                                        </div>
                                    </TabPanel>
                                    <TabPanel header="Add User">
                                        <div className="body mt-2">
                                            <form autoComplete='off' onSubmit={addAdmin}>
                                                <div className="row clearfix">
                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="form-group">
                                                            <input type="text" className="form-control" value={name} onInput={e=>setname(e.target.value)} placeholder="First Name *" required/>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="form-group">
                                                            <input type="text" className="form-control" value={surname} onInput={e=>setsurname(e.target.value)} placeholder="Last Name *" required/>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="form-group">
                                                            <input type="email" minLength={8} className="form-control" value={email} onInput={e=>setemail(e.target.value)} placeholder="Email Address *" required/>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="form-group">
                                                            <input type="password" minLength={8} className="form-control" value={password} onInput={e=>setpassword(e.target.value)} placeholder="Password *" required/>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <button type="submit" className="btn btn-primary" disabled={loading}>{ loading ? `Please wait...` : `Add Administrator`}</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </TabPanel>
                                </TabView>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}
export default PageUserList
