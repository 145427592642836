import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import axios from 'axios';

const PageNews = (props) => {

    const toast = useRef()
    const [projects, setprojects] = useState([])

    if(!localStorage.getItem(`_auth`)){
		props.history.push('/login')
	}

    const getprojects = ()=>{
        axios.get(`/posts`).then(response=>{
            setprojects(response.data.posts)
        }).catch(err=>{
            try{
                toast.current.show({ severity: 'warn', summary: 'Error!', detail: err.response.data.message, life: 3000 })
            }catch(e){
                toast.current.show({ severity: 'warn', summary: 'Error!', detail: err.message, life: 3000 })
            }
        })
    }

    useEffect(() => {
        document.title = `Projects | DoGood`
        getprojects()
    }, [])
    

    return (
        <>
        
            <Toast ref={toast} />
            <div className="container-fluid news">
                <div className="block-header">
                    <div className="row clearfix">
                        <div className="col-md-6 col-sm-12">
                            <h2>Projects</h2>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/">Overview</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Projects</li>
                                </ol>
                            </nav>
                        </div>
                        <div className="col-md-6 col-sm-12 text-right hidden-xs">
                            {/* <Link to="/" className="btn btn-sm btn-primary btn-round" title="">Add New</Link> */}
                        </div>
                    </div>
                </div>
                <div className="row clearfix">
                    { projects.map(project => 
                        <div className="col-3" key={project.id}>
                            <div className="card-group m-b-30">
                            <div className="card n_category">
                                <span className="sub_n_category p_right bg-blue">{project.category.toUpperCase()}</span>
                                <img className="card-img-top" style={{ height: 300 }} src={project.poster} alt="Card cap" />
                                <div className="body">
                                    <h5 className="card-title"><a target='_blank' href={`https://dogood.vercel.app/projects/${project.id}`}>{project.title}</a></h5>
                                    <p className="card-text">{project.content.substring(0, 200)}...</p>
                                </div>
                                <div className="card-footer">
                                    <small className="text-muted">Last updated {project.created} ago</small>
                                    <button className='btn btn-danger btn-sm pull-right mb-10'>Delete</button>
                                </div>
                            </div>
                        </div>
                    </div>) }
                </div>
            </div>
        </>
    );

}
export default PageNews
