import axios from 'axios';
import { Toast } from 'primereact/toast';
import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';

const Login = (props)=> {

	const toast = useRef()
	const [email, setemail] = useState()
	const [password, setpassword] = useState()
	const [platform, setplatform] = useState(false)
	const [loading, setloading] = useState(false)

	if(localStorage.getItem(`_auth`)){
		props.history.push('/')
	}

	const login = (e)=>{
		e.preventDefault()

		if(!email || !password){
			return toast.current.show({ severity: 'info', summary: 'Error!', detail: `Please provide email address and password to proceed.`, life: 3000 })
		}

		setloading(true)

		axios.post(`/sessions`, { email, password }, {
			headers: {
				'x-platform': platform ? 'kitso' : 'main'
			}
		}).then(res=>{
			setloading(false)
			const { accessToken, refreshToken, user } = res.data
			if(user.type !== `admin`){
				return toast.current.show({ severity: 'error', summary: 'Account Not Found!', detail: `There is no ${platform ? 'Kitso' : 'DoGood'} administrator account with the provided credentials.`, life: 3000 })
			}
			localStorage.setItem(`_auth`, accessToken)
			localStorage.setItem(`_auth0`, refreshToken)
			localStorage.setItem(`_platform`, platform ? 'kitso' : 'main')
			localStorage.setItem(`$_ref`, btoa(JSON.stringify(user)))
			props.history.push('/')
		}).catch(err=>{
			setloading(false)
            try{
                toast.current.show({ severity: 'error', summary: 'Error!', detail: err.response.data.message, life: 3000 })
            }catch(e){
                toast.current.show({ severity: 'warn', summary: 'Error!', detail: err.message, life: 3000 })
            }
		})

	}
			
	return (
		<>
			<Toast ref={toast} />
			<div className="pattern">
				<span className="red"></span>
				<span className="indigo"></span>
				<span className="blue"></span>
				<span className="green"></span>
				<span className="orange"></span>
			</div>
			<div className="auth-main particles_js">
				<div className="auth_div vivify popIn">
					<div className="auth_brand">
						<Link className="navbar-brand" to="/">
							<img src="../assets/images/icon.svg" width="35" height="35" className="d-inline-block align-top mr-2" alt="" />DoGood
						</Link>
					</div>
					<div className="card">
						<div className="body">
							<p className="lead">Login to DoGood Admin</p>
							<form className="form-auth-small m-t-20" autoComplete='off' onSubmit={login} action="/">
								<div className="form-group">
									<label htmlFor="signin-email" className="control-label sr-only">Email</label>
									<input type="email" className="form-control round" id="signin-email" value={email} onInput={e=>setemail(e.target.value)} placeholder="Email" required />
								</div>
								<div className="form-group">
									<label htmlFor="signin-password" className="control-label sr-only">Password</label>
									<input type="password" className="form-control round" id="signin-password" value={password} onInput={e=>setpassword(e.target.value)} placeholder="Password" required />
								</div>
								<div className="form-group clearfix">
									<label className="fancy-checkbox element-left">
										<input type="checkbox" value={platform} onChange={e=>setplatform(e.currentTarget.checked)} />
										<span>Login as Kitso Admin</span>
									</label>
								</div>
								<button type="submit" className="btn btn-primary btn-round btn-block" disabled={loading}>{ loading ? `Please wait...` : `Login` }</button>
								{/* <div className="bottom"> */}
									{/* <span className="helper-text m-b-10"><i className="fa fa-lock"></i> <Link to="/forgotpassword">Forgot password?</Link></span> */}
									{/* <span>Don't have an account? <Link to="/signup">Register</Link></span> */}
								{/* </div> */}
							</form>
						</div>
					</div>
				</div>
				<div id="particles-js"></div>
			</div>
		</>
	);
}

export default Login