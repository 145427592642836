import axios from 'axios';
import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { ConfirmDialog } from 'primereact/confirmdialog'
import { confirmDialog } from 'primereact/confirmdialog'
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { TabView, TabPanel } from 'primereact/tabview';
import { InputText } from "primereact/inputtext"
import { InputTextarea } from 'primereact/inputtextarea';
import { MultiSelect } from 'primereact/multiselect'
import { Toast } from 'primereact/toast';
import readXlsxFile from 'read-excel-file'
import { Dropdown } from 'primereact/dropdown';
import { FileUpload } from 'primereact/fileupload';

const ProductList = (props) => {

    const toast = useRef()
    const [products, setproducts] = useState([])
    const [loading, setloading] = useState(false)
    const [bulkadd, setbulkadd] = useState(false)

    const tag = [
        { name: 'Trending', code: 'trending' },
        { name: 'On Sale', code: 'sale' },
        { name: 'Outdoor', code: 'outdoor' },
        { name: 'Winter', code: 'winter' },
        { name: 'Summer', code: 'summer' },
        { name: 'Women', code: 'women' },
        { name: 'Men', code: 'men' },
        { name: 'Unisex', code: 'unisex' }
    ];

    const [title, settitle] = useState()
    const [tags, settags] = useState()
    const [description, setdescription] = useState()
    const [category, setcategory] = useState()
    const [price, setprice] = useState()
    const [quantity, setquantity] = useState()

    if(!localStorage.getItem(`_auth`)){
		props.history.push('/login')
	}

    const loadProducts = ()=>{
        axios.get(`/products`).then(res=>{
            setproducts(res.data.products)
        }).catch(err=>{
            try{
                toast.current.show({ severity: 'warn', summary: 'Error!', detail: err.response.data.message, life: 3000 })
            }catch(e){
                toast.current.show({ severity: 'warn', summary: 'Error!', detail: err.message, life: 3000 })
            }
        })
    }

    const del = async(product)=>{
        try{
            await axios.delete(`/products/${product.id}`)
            toast.current.show({ severity: 'success', summary: 'Deleted!', detail: `You have deleted the product: ${product.title}`, life: 3000 })
            loadProducts()
        }catch(err){
            try{
                toast.current.show({ severity: 'error', summary: 'Error!', detail: err.response.data.message, life: 3000 })
            }catch(e){
                toast.current.show({ severity: 'warn', summary: 'Error!', detail: err.message, life: 3000 })
            }
        }

    }

    const cancel = ()=>{}

    const deleteProduct = (product)=>{
        confirmDialog({
            message: 'Do you want to delete this product?',
            header: `Delete ${product.title}?`,
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept: ()=>del(product),
            reject: cancel
        });
    }

    const bulkupload = async e => {
        let products = []

        readXlsxFile(e.target.files[0]).then((data) => {
            data.map(product=>
                products.push({
                    name: product[0],
                    surname: product[1],
                    email: product[2],
                    password: product[3],
                    type: product[4] || `basic`
                })
            )
            submitbulk(products)
        })
    }

    const submitbulk = async data => {
        toast.current.show({ severity: 'info', summary: 'Processing!', detail: `Your bulk upload request is being processed, please wait...` })

        try{
            let response = await axios.post(`/products/bulk`, { users: data }, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('_auth')}`,
                    'x-refresh': `${localStorage.getItem('_auth0')}`
                }
            })
            console.log(response.data)
            toast.current.show({ severity: 'success', summary: 'Completed Successfully!', detail: `Your bulk add request was processed successfully, users can now purchase from the marketplace.`, life: 3000 })
            setloading(false)
            setbulkadd(false)
            loadProducts()
        }catch(err){
            setloading(false)
            try{
                toast.current.show({ severity: 'error', summary: 'Error!', detail: err.response.data.message, life: 3000 })
            }catch(e){
                toast.current.show({ severity: 'warn', summary: 'Error!', detail: err.message, life: 3000 })
            }
        }
    }

    useEffect(() => {
      loadProducts()
    }, [])
    

    return (
        <>
            <Toast ref={toast} />
            <ConfirmDialog />
            <Dialog header="Bulk Add Products" visible={bulkadd} onHide={()=>setbulkadd(false)}>
                <div className="row clearfix">
                    <div className="col-md-12 col-sm-12">
                        <input type='file' onChange={bulkupload} disabled={loading} accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' title="Upload Excel Sheet with Products Details" placeholder='Upload Excel Sheet with Products Details' />
                    </div>
                </div>
            </Dialog>

            <div className="container-fluid">
                <div className="block-header">
                    <div className="row clearfix">
                        <div className="col-md-6 col-sm-12">
                            <h2>Product List</h2>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/">Store</Link></li>
                                    <li className="breadcrumb-item"><Link to="/">Marketplace</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Products</li>
                                </ol>
                            </nav>
                        </div>
                        <div className="col-md-6 col-sm-12 text-right hidden-xs">
                            <Link to="/" onClick={e=>{e.preventDefault(); setbulkadd(true)}} className="btn btn-sm btn-primary mr-1" title="">Bulk Add Products</Link>
                            {/* <Link to="https://themeforest.net/item/oculux-bootstrap-4x-admin-dashboard-clean-modern-ui-kit/23091507" className="btn btn-sm btn-success" title="Themeforest"><i className="icon-basket"></i> Buy Now</Link> */}
                        </div>
                    </div>
                </div>
                <TabView>
                    <TabPanel header="Products">
                        <div className="row clearfix">
                            <div className="col-12">
                                <DataTable value={products} editMode='row' removableSort paginator rows={10} rowsPerPageOptions={[5, 10, 25, 50]} tableStyle={{ minWidth: '50rem' }}>
                                    <Column field="id" header="ID"></Column>
                                    <Column field="title" sortable header="Title"></Column>
                                    <Column field="status" sortable header="Status"></Column>
                                    <Column field="quantity" sortable header="Quantity"></Column>
                                    <Column field="sold" sortable header="Sold"></Column>
                                    <Column field="price" header="Unit Price"></Column>
                                    <Column field="updated" header="Last Sale"></Column>
                                    <Column rowEditor headerStyle={{ width: '10%', minWidth: '8rem' }} bodyStyle={{ textAlign: 'center' }}></Column>
                                </DataTable>
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel header="Add New Product">
                        <div className="row clearfix">
                            <div className="col-6">
                                <div className="form-group">
                                    <InputText value={title} placeholder='Title of Product' style={{ width: '100%' }} onChange={(e) => settitle(e.target.value)} />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group">
                                    <MultiSelect value={tags} onChange={(e) => settags(e.value)} options={tag} optionLabel="name" display="chip" 
                                    placeholder="Select Tags" maxSelectedLabels={3} style={{ width: '100%' }} />
                                </div>
                            </div>
                        </div>
                        
                        <div className="row clearfix">
                            <div className="col-6">
                                <div className="form-group">
                                    <InputTextarea placeholder='Description of Product' value={description} onChange={(e) => setdescription(e.target.value)} rows={3} style={{ width: '100%' }} />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group">
                                    <Dropdown value={category} options={[
                                        {
                                            label: 'Clothing',
                                            value: 'clothing'
                                        },
                                        {
                                            label: 'Accessories',
                                            value: 'accessories'
                                        },
                                        {
                                            label: 'Jackets',
                                            value: 'jackets'
                                        },
                                        {
                                            label: 'T-Shirts',
                                            value: 'tshirts'
                                        },
                                        {
                                            label: 'Hoodies',
                                            value: 'hoodies'
                                        }
                                    ]} placeholder='Product Category' style={{ width: '100%' }} onChange={(e) => setcategory(e.target.value)} />
                                </div>
                            </div>
                        </div>
                        <div className="row clearfix">
                            <div className="col-6">
                                <div className="form-group">
                                    <InputText value={price} keyfilter='money' placeholder='Product Unit Price' style={{ width: '100%' }} onChange={(e) => setprice(e.target.value)} />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group">
                                    <InputText value={quantity} keyfilter='int' placeholder='Product Quantity in Stock' style={{ width: '100%' }} onChange={(e) => setquantity(e.target.value)} />
                                </div>
                            </div>
                        </div>
                        <div className="row clearfix">
                            <div className="col-6">
                                <div className="form-group">
                                    <FileUpload mode="basic" multiple chooseLabel='Choose Product Pictures' name="pictures[]" url="/products" accept="image/*" maxFileSize={1000000} onUpload={console.log} />
                                </div>
                            </div>
                        </div>
                    </TabPanel>
                </TabView>
            </div>
        </>
    );

}
export default ProductList
