import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { ConfirmDialog } from 'primereact/confirmdialog'
import { confirmDialog } from 'primereact/confirmdialog'
import { TabView, TabPanel } from 'primereact/tabview';
import axios from 'axios';

const PageTransactionList = (props) => {

    const toast = useRef(null);
    const [transactions, settransactions] = useState([])

    if(!localStorage.getItem(`_auth`)){
		props.history.push('/login')
	}

    const gettransactions = () => {
        axios.get(`/transactions`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('_auth')}`,
                'x-refresh': `${localStorage.getItem('_auth0')}`
            }
        }).then(response=>{
            settransactions(response.data.transactions)
        }).catch(err=>{
            try{
                toast.current.show({ severity: 'warn', summary: 'Error!', detail: err.response.data.message, life: 3000 })
            }catch(e){
                toast.current.show({ severity: 'warn', summary: 'Error!', detail: err.message, life: 3000 })
            }
        })
    }

    useEffect(() => {
        document.title = `Transactions | DoGood`
        gettransactions()
    }, [])
    

    return (
        <>
            <Toast ref={toast} />
            <ConfirmDialog />
            <div className="container-fluid">
                <div className="block-header">
                    <div className="row clearfix">
                        <div className="col-md-6 col-sm-12">
                            <h2>Transaction List</h2>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/">Store</Link></li>
                                    <li className="breadcrumb-item"><Link to="/">Marketplace</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Transactions</li>
                                </ol>
                            </nav>
                        </div>
                        <div className="col-md-6 col-sm-12 text-right hidden-xs">
                        </div>
                    </div>
                </div>
                <div className="row clearfix">
                    <div className="col-lg-12">
                        <div>
                            <div className='card'>
                                <TabView>
                                    <TabPanel header="Transactions">
                                        <div className="table-responsive">
                                            <table className="table table-hover table-custom spacing8">
                                                <thead>
                                                    <tr>
                                                        <th className="w60">Transaction Details</th>
                                                        <th>Transaction Reference</th>
                                                        <th>Transaction Amount</th>
                                                        <th>Transaction Status</th>
                                                        <th>User ID</th>
                                                        <th>Created Date</th>
                                                        <th>Update Date</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    { transactions.map(transaction => <tr key={transaction.id}>
                                                        <td>
                                                            <h6 className="mb-0">{transaction.title}</h6>
                                                            <span>{transaction.description}</span>
                                                        </td>
                                                        <td><span className={`text-primary`}>{transaction.reference.toUpperCase()}</span></td>
                                                        <td>P {transaction.amount}</td>
                                                        <td><span className={`badge badge-${transaction.status === 'failed' ? 'danger' : 'success'}`}>{transaction.status}</span></td>
                                                        <td>{transaction.user}</td>
                                                        <td>{transaction.created}</td>
                                                        <td>{transaction.updated}</td>
                                                    </tr>) }
                                                </tbody>
                                            </table>
                                        </div>
                                    </TabPanel>
                                </TabView>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}
export default PageTransactionList
