import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { ConfirmDialog } from 'primereact/confirmdialog'
import { confirmDialog } from 'primereact/confirmdialog'
import { TabView, TabPanel } from 'primereact/tabview';
import axios from 'axios';

const PageCategories = (props) => {

    const toast = useRef(null);
    const [title, settitle] = useState()
    const [loading, setloading] = useState()
    const [categories, setcategories] = useState([])

    if(!localStorage.getItem(`_auth`)){
		props.history.push('/login')
	}

    const getcategories = () => {
        axios.get(`/categories`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('_auth')}`,
                'x-refresh': `${localStorage.getItem('_auth0')}`,
                'x-platform': localStorage.getItem(`_platform`)
            }
        }).then(response=>{
            setcategories(response.data.categories)
        }).catch(err=>{
            try{
                toast.current.show({ severity: 'warn', summary: 'Error!', detail: err.response.data.message, life: 3000 })
            }catch(e){
                toast.current.show({ severity: 'warn', summary: 'Error!', detail: err.message, life: 3000 })
            }
        })
    }

    const del = async(category)=>{
        try{
            await axios.delete(`/categories/${category.id}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('_auth')}`,
                    'x-refresh': `${localStorage.getItem('_auth0')}`,
                    'x-platform': localStorage.getItem(`_platform`)
                }
            })
            toast.current.show({ severity: 'success', summary: 'Deleted!', detail: `You have deleted the category: ${category.title}`, life: 3000 })
            getcategories()
        }catch(err){
            try{
                toast.current.show({ severity: 'error', summary: 'Error!', detail: err.response.data.message, life: 3000 })
            }catch(e){
                toast.current.show({ severity: 'warn', summary: 'Error!', detail: err.message, life: 3000 })
            }
        }

    }
    
    const cancel = ()=>{}

    const deleteCategory = (category)=>{
        confirmDialog({
            message: 'Do you want to delete this category?',
            header: `Delete ${category.title}?`,
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept: ()=>del(category),
            reject: cancel
        });
    }

    const addCategory = async e => {
        e.preventDefault();
        
        setloading(true)

        try{
            await axios.post(`/categories`, {
                title,
            }, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('_auth')}`,
                    'x-refresh': `${localStorage.getItem('_auth0')}`,
                    'x-platform': localStorage.getItem(`_platform`)
                }
            })
            getcategories()
            toast.current.show({ severity: 'success', summary: 'Added!', detail: `${title} has been added to your categories.`, life: 3000 })
            setloading(false)
            settitle('')
        }catch(err){
            setloading(false)
            try{
                toast.current.show({ severity: 'error', summary: 'Error!', detail: err.response.data.message, life: 3000 })
            }catch(e){
                toast.current.show({ severity: 'warn', summary: 'Error!', detail: err.message, life: 3000 })
            }
        }
    }

    useEffect(() => {
        document.title = `Categories | DoGood`
        getcategories()
    }, [])
    

    return (
        <>
            <Toast ref={toast} />
            <ConfirmDialog />
            <div className="container-fluid">
                <div className="block-header">
                    <div className="row clearfix">
                        <div className="col-md-6 col-sm-12">
                            <h2>Category List</h2>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/">Overview</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Categories</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
                <div className="row clearfix">
                    <div className="col-lg-12">
                        <div>
                            <div className='card'>
                                <TabView>
                                    <TabPanel header="Categories">
                                        <div className="container-fluid">
                                            { categories.map(category=><div key={category.id} className="alert alert-dark alert-dismissible" role="alert">
                                                <button type="button" onClick={()=>deleteCategory(category)} className="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                                {category.title}
                                            </div>) }
                                        </div>
                                    </TabPanel>
                                    <TabPanel header="Add Category">
                                        <div className="body mt-2">
                                            <form autoComplete='off' onSubmit={addCategory}>
                                                <div className="row clearfix">
                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="form-group">
                                                            <input type="text" className="form-control" value={title} onInput={e=>settitle(e.target.value)} placeholder="Category Name *" required/>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <button type="submit" className="btn btn-primary" disabled={loading}>{ loading ? `Please wait...` : `Add Category`}</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </TabPanel>
                                </TabView>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}
export default PageCategories
