import axios from 'axios';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';

const PageVideos = (props) => {

    const toast = useRef()
    const [videos, setvideos] = useState([])
    const platform = localStorage.getItem("_platform")

    if(!localStorage.getItem(`_auth`)){
		props.history.push('/login')
	}

    const getvideos = () => {
        axios.get(`/posts`, {
            headers: {
                'x-platform': localStorage.getItem(`_platform`)
            }
        }).then(response=>{
            setvideos(response.data.posts.filter(post=>post.type === 'video'))
        }).catch(err=>{
            try{
                toast.current.show({ severity: 'warn', summary: 'Error!', detail: err.response.data.message, life: 3000 })
            }catch(e){
                toast.current.show({ severity: 'warn', summary: 'Error!', detail: err.message, life: 3000 })
            }
        })
    }

    const del = async video =>{
        try{
            await axios.delete(`/posts/${video.id}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('_auth')}`,
                    'x-refresh': `${localStorage.getItem('_auth0')}`,
                    'x-platform': localStorage.getItem(`_platform`)
                }
            })
            toast.current.show({ severity: 'success', summary: 'Deleted!', detail: `You have deleted the video: ${video.title}`, life: 3000 })
            getvideos()
        }catch(err){
            try{
                toast.current.show({ severity: 'error', summary: 'Error!', detail: err.response.data.message, life: 3000 })
            }catch(e){
                toast.current.show({ severity: 'warn', summary: 'Error!', detail: err.message, life: 3000 })
            }
        }
    }

    const deleteVideo = (video)=>{
        confirmDialog({
            message: 'Do you want to delete this video?',
            header: `Delete ${video.title}?`,
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept: ()=>del(video),
            reject: ()=>{}
        });
    }

    useEffect(() => {
      document.title = `Videos | DoGood`
      getvideos()
    }, [])
    

    return (
        <>
            <Toast ref={toast} />
            <ConfirmDialog />
            <div className="container-fluid">
                <div className="block-header">
                    <div className="row clearfix">
                        <div className="col-md-6 col-sm-12">
                            <h2>Videos</h2>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/">Overview</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Videos</li>
                                </ol>
                            </nav>
                        </div>
                        <div className="col-md-6 col-sm-12 text-right hidden-xs">
                            {/* <Link to="/page-blog-post" className="btn btn-sm btn-primary btn-round" title="">New Post</Link> */}
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid">
                <div className="row">
                    {
                        videos.length === 0 ?
                        <div className="card">
                            <div className="header">
                                <h2>No Videos</h2>
                            </div>
                            <div className="body">
                                <span>Videos are not available on this dashboard at the moment. In the meantime, focus on managing other posts and stay tuned for future updates regarding video uploads.</span>
                            </div>
                        </div> : <></>
                    }
                    { videos.map( video=><div key={video.id} className="col-lg-4 col-md-6">
                        <div className="single-blog">
                            <div className="post-thumb">
                                <video style={{ width: '100%', borderRadius: 10 }} src={video.image} controls/>
                            </div>

                            <div className="post-content overflow">
                                <h2 className="post-title bold"><a href={`https://kitso.vercel.app/post/${video.id}`} target="_blank" rel="noopener noreferrer">{video.title}</a></h2>
                                {/*<h3 className="post-author"><Link to="/">Posted {video.created} ago</Link></h3>*/}
                                {/* <p>Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi. Nam liber [...]</p> */}
                                {/* <Link to="/" className="read-more">View More</Link> */}
                                <div className="post-bottom overflow">
                                    <ul className="nav navbar-nav post-nav">
                                        <li><i className="fa fa-calendar "></i>{video.created} ago</li>
                                        <li><i className="fa fa-comments"></i>{video.comments.length} comments</li>
                                        <li><Link className="text-danger" to={"/"} onClick={e=>{e.preventDefault(); deleteVideo(video)}}><i className="fa fa-trash text-danger"></i> Delete</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>) }
                    {/* <nav aria-label="Page navigation example">
                        <ul className="pagination">
                            <li className="page-item"><Link className="page-link" to="/">Previous</Link></li>
                            <li className="page-item"><Link className="page-link" to="/">1</Link></li>
                            <li className="page-item"><Link className="page-link" to="/">2</Link></li>
                            <li className="page-item"><Link className="page-link" to="/">3</Link></li>
                            <li className="page-item"><Link className="page-link" to="/">Next</Link></li>
                        </ul>
                    </nav> */}
                </div>
            </div>
        </>
    );

}
export default PageVideos
